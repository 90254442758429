<template>
  <div class="flex flex-col">
    <Table
      :rows="asset"
      :columns="columns"
      :footer="true"
      :row-style-class="rowStyleClass"
      :collapsible-component="collapsibleComponent"
      wrapper-class="rounded-10 border border-gray-300 overflow-x-auto"
    />
  </div>
</template>

<script type="text/jsx">
import classnames from 'classnames';
import CInput from 'devotedcg-ui-components/CInput.vue';
import Table from 'devotedcg-ui-components/Table/TableCollapsible.vue';
import SignedImageView from 'devotedcg-ui-components-v2/SignedImageView.vue';
import { meanBy, sumBy } from 'lodash';

import RequestStageTable from '@/components/Request/RequestStageTable.vue';

export default {
  name: 'RequestCalculationAssets',
  components: {
    Table,
  },
  props: {
    asset: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    total() {
      return {
        time: sumBy(this.asset, 'time'),
        cost: sumBy(this.asset, 'cost'),
        totalCost: sumBy(this.asset, 'totalCost'),
        profit: sumBy(this.asset, 'profit'),
        totalProfit: sumBy(this.asset, 'totalProfit'),
        margin: meanBy(this.asset, 'margin'),
        revenue: sumBy(this.asset, 'revenue'),
        totalRevenue: sumBy(this.asset, 'totalRevenue'),
        quantity: sumBy(this.asset, (asset) => +asset.quantity),
      };
    },
    columns() {
      return [
        {
          label: 'Asset',
          field: ' ',
          thClass: 'text-left',
          sortable: false,
          cell: ({ row }) => <div class="w-full h-full flex flex-row items-center space-x-4">
            { (() => {
              const thumb = row.subBatchItems?.[0]?.attachmentLinks?.[0]?.attachment?.thumb;
              if (!thumb) {
                return <span />;
              }
              return <div class="w-10 h-10 rounded-10 bg-gray-100 overflow-hidden flex-shrink-0">
                <SignedImageView
                  class="w-full h-full object-cover object-center"
                  src={thumb}
                />
              </div>;
            })() }
            <div class="flex flex-col min-w-120px!">
              <span class="font-semibold leading-6">{ row.name }</span>
              <span class="text-sm text-tertiary font-normal">{ row.asset_type }</span>
            </div>
          </div>,
          footerCell: () => <span class="text-normal font-semibold">Asset Total</span>,
        },
        {
          label: 'Time, d',
          field: ' ',
          thClass: 'text-right',
          sortable: false,
          cell: ({ row }) => <div class="h-full flex flex-row items-center justify-end">
            <span class="text-body3 font-robo whitespace-nowrap">
              { this.$numeral(parseFloat(row.time)).format('0.[00]') }
            </span>
          </div>,
          footerCell: () => <div class="h-full flex flex-row items-center justify-end">
            <span class="text-normal font-semibold whitespace-nowrap">
              { this.$numeral(parseFloat(this.total.time)).format('0.[00]') }
            </span>
          </div>,
        },
        {
          label: 'Talent rate, $/d',
          field: ' ',
          thClass: 'text-right',
          sortable: false,
          cell: ({ row }) => <div>
            { this.readonly ? <span class="text-body3 font-robo">
                { row.rate }
              </span> : <CInput
              hideZeroOnFocus={true}
              onFocus-change={(e) => this.onFocusChange({ value: e, row })}
              onInput={(e) => this.onInput(row.id, +e, 'rate')}
              mask={{ mask: '9[99999]', placeholder: '' }}
              class="w-100"
              value={ row.rate }
              textRight={true}
            /> }
          </div>,
        },
        {
          label: 'Rate, $/d',
          field: ' ',
          thClass: 'text-right',
          sortable: false,
          cell: ({ row }) => <div class="h-full flex flex-row items-center justify-end">
            { this.readonly ? <span class="text-body3 font-robo">
                { row.client_rate }
              </span> : <CInput
              hideZeroOnFocus={true}
              onFocus-change={(e) => this.onFocusChange({ value: e, row })}
              onInput={(e) => this.onInput(row.id, +e, 'client_rate' )}
              mask={{ mask: '9[99999]', placeholder: '' }}
              class="w-100"
              value={ row.client_rate }
              textRight={true}
            /> }
          </div>,
        },
        {
          label: 'Unit price',
          field: ' ',
          thClass: 'text-right',
          sortable: false,
          cell: ({ row }) => <div class={classnames('h-full flex flex-row items-center justify-end', {
            'opacity-30': this.$wait.is(`fetch.estimation.${row.id}`),
          })}>
            <span class="text-body3 font-robo whitespace-nowrap">
              { this.$numeral(parseFloat(row.cost)).format('$0,0') }
            </span>
          </div>,
          footerCell: () => <div class="h-full flex flex-row items-center justify-end">
            <span class="text-sm font-normal whitespace-nowrap">
              { this.$numeral(parseFloat(this.total.cost)).format('$0,0') }
            </span>
          </div>,
        },
        {
          label: 'Asset qty',
          field: ' ',
          thClass: 'text-right',
          sortable: false,
          cell: ({ row }) => <div class="h-full flex flex-row items-center justify-end">
            <span class="text-body3 font-robo whitespace-nowrap">
              { this.$numeral(parseInt(row.quantity, 10)).format('0,0') }
            </span>
          </div>,
          footerCell: () => <div class="h-full flex flex-row items-center justify-end">
            <span class="text-sm font-normal whitespace-nowrap">
              { this.$numeral(parseInt(this.total.quantity, 10)).format('0,0') }
            </span>
          </div>,
        },
        {
          label: 'Cost',
          field: ' ',
          thClass: 'text-right',
          sortable: false,
          cell: ({ row }) => <div class={classnames('h-full flex flex-row items-center justify-end', {
            'opacity-30': this.$wait.is(`fetch.estimation.${row.id}`),
          })}>
            <span class="text-body3 font-robo whitespace-nowrap">
              { this.$numeral(parseFloat(row.totalCost)).format('$0,0') }
            </span>
          </div>,
          footerCell: () => <div class="h-full flex flex-row items-center justify-end">
            <span class="text-sm font-normal whitespace-nowrap">
              { this.$numeral(parseFloat(this.total.totalCost)).format('$0,0') }
            </span>
          </div>,
        },
        {
          label: 'Profit',
          field: ' ',
          thClass: 'text-right',
          sortable: false,
          cell: ({ row }) => <div class={classnames('h-full flex flex-row items-center justify-end', {
            'opacity-30': this.$wait.is(`fetch.estimation.${row.id}`),
          })}>
            <span class="text-body3 font-robo whitespace-nowrap">
              { this.$numeral(parseFloat(row.totalProfit)).format('$0,0') }
            </span>
          </div>,
          footerCell: () => <div class="h-full flex flex-row items-center justify-end">
            <span class="text-sm font-normal whitespace-nowrap">
                {this.$numeral(parseFloat(this.total.totalProfit)).format('$0,0')}
            </span>
          </div>,
        },
        {
          label: 'Margin',
          field: ' ',
          thClass: 'text-right',
          sortable: false,
          cell: ({ row }) => <div class={classnames('h-full flex flex-row items-center justify-end', {
            'opacity-30': this.$wait.is(`fetch.estimation.${row.id}`),
          })}>
            <span class="text-body3 font-robo whitespace-nowrap">
              { this.$numeral(parseFloat(row.margin)).format('0%') }
            </span>
          </div>,
          footerCell: () => <div class="h-full flex flex-row items-center justify-end">
            <span class="text-sm font-normal whitespace-nowrap">
              { this.$numeral(parseFloat(this.total.margin)).format('0,0%') }
            </span>
          </div>,
        },
        {
          label: 'Revenue',
          field: ' ',
          thClass: 'text-right',
          sortable: false,
          cell: ({ row }) => <div class={classnames('h-full flex flex-row items-center justify-end', {
            'opacity-30': this.$wait.is(`fetch.estimation.${row.id}`),
          })}>
            <span class="text-body3 font-robo text-body3-bold whitespace-nowrap">
              { this.$numeral(parseFloat(row.totalRevenue)).format('$0,0') }
            </span>
          </div>,
          footerCell: () => <div class="h-full flex flex-row items-center justify-end">
            <span class="text-normal font-bold whitespace-nowrap">
               { this.$numeral(parseFloat(this.total.totalRevenue)).format('$0,0') }
            </span>
          </div>,
        },
      ];
    },
  },
  methods: {
    collapsibleComponent({ row }) {
      let { rows = [] } = row;
      const { id } = row;
      rows = rows.map((obj) => ({
        ...obj,
        status: obj.enabled,
      }));
      return <div class="py-4 px-4 rounded-10 bg-gray-100 space-y-1">
        <RequestStageTable
          onShow-asset-modal={() => this.showAssetSpecsModal({ id })}
          onInput={(e) => this.onInputTable(row, e)}
          onInput-blur={() => this.onTableBlur(row.id)}
          rows={rows}
          styleClass="table-stage"
          readonly={this.readonly}
        />
        {/* eslint-disable-next-line no-constant-condition */}
        { this.readonly
            ? <div class="h-12 flex flex-row items-center px-4">
              <span class="text-secondary44 text-sm">
                <b class="font-semibold">Risks, %:</b>
                {row.risk}
              </span>
            </div>
            : <CInput
              onFocus-change={(e) => this.onFocusChange({ value: e, row })}
              onInput={(e) => this.onInput(row.id, +e, 'risk')}
              class="w-100"
              hideZeroOnFocus={true}
              placeholder="Risks, %"
              mask={{ mask: '9[99999]', placeholder: '' }}
              value={row.risk}
          />
        }
      </div>;
    },

    onInput(id, value, key) {
      const data = this.asset;
      const index = data.findIndex((item) => item.id === id);
      if (data[index]) {
        data[index][key] = value;
        data[index].children[0][key] = value;
        this.$emit('input', {
          index,
          row: data[index],
        });
      }
    },

    onInputTable(row, value) {
      const data = this.asset;
      const index = data.findIndex((item) => item.id === row.id);
      if (data[index]) {
        data[index].options = value;
        data[index].children[0].rows = value;
        this.$emit('input', {
          index,
          row: data[index],
        });
      }
    },

    onFocusChange(params) {
      if (!params.value) {
        this.$emit('input-blur', params.row);
      }
    },

    onTableBlur(id) {
      const data = this.asset;
      const index = data.findIndex((item) => item.id === id);
      if (data[index]) {
        this.$emit('input-blur', data[index]);
      }
    },

    showAssetSpecsModal(event) {
      this.$emit('show-asset-modal', event);
    },

    rowStyleClass(event) {
      if (!event?.children?.length) {
        return 'no-outline';
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .table {
  thead {
    th {
      white-space: nowrap;
    }
  }
  tbody {
    th {
      & > div {
        @apply flex;
        @apply flex-row;
        @apply items-center;
        @apply justify-end;
      }
    }
    .table-stage {
      thead,
      tbody,
      tfoot {
        background-color: transparent;
        tr {
          th,
          td {
            background-color: transparent;
          }
        }
      }
    }
  }
}
</style>
